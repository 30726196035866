import React, { useEffect, useState } from "react";
import { Image, InfiniteScroll, List, Card } from "antd-mobile";
import { FloatButton } from "antd";
import "animate.css";
import styles from "./index.module.less";
const list = require("../assets/movieData.json");

const App = () => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const loadMore = () => {
    const start = data.length;
    const end = start + 3;
    const append = list.slice(start, end);
    setData((val) => [...val, ...append]);
    setHasMore(append.length > 0);
  };

  useEffect(() => {
    const append = list.slice(0, 3);
    setData(append);
  }, []);

  return (
    <>
      <List>
        {data.map((item, index) => (
          <List.Item key={index} style={{ background: "#fff" }}>
            <Card
              title={item.title}
              onClick={() => {
                window.open(
                  `https://www.huidge.cn/?id=${item.id}&flag=${item.flag}`
                );
              }}
              className={`${styles.card} animate__animated ${
                index % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight"
              }`}
            >
              <div className={styles.img}>
                <Image src={item.pic} width={"100%"} height={350} />
              </div>
            </Card>
          </List.Item>
        ))}
      </List>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
      <FloatButton.BackTop visibilityHeight={600} duration={500} />
    </>
  );
};

export default App;
