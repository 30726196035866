import React, { useEffect } from "react";
import Hls from "hls.js";

const App = (props) => {
  const { url } = props;
  useEffect(() => {
    var video = document.getElementById("video");
    if (Hls.isSupported() && url) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
    }
  }, [url]);
  return (
    <video
      id="video"
      // muted="muted"
      controls="controls"
      autoPlay="autoplay"
      style={{ width: "100%", height: "300px" }}
    ></video>
  );
};

export default App;
