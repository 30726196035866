import React, { useState, useEffect } from "react";
import { Image } from "antd";
import { Toast } from "antd-mobile";

function App() {
  const [list, setList] = useState([]);
  const asyncFetch = () => {
    // fetch(`./getList.php?index=6`)
    fetch(`/sex/getList.php?index=6`)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setList(result.data);
        } else {
          Toast.show(result.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    asyncFetch();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ height: "40vh" }}>
      {list.map((item) => (
        <Image width={"50%"} src={item.url} />
      ))}
    </div>
  );
}

export default App;
