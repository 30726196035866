import React, { useState, useEffect } from "react";
import {  Toast,Grid, Button,NoticeBar  } from "antd-mobile";
import Video from "../components/Video";
import Feedback from "../Feedback";
import "./index.module.less";

function App() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState();
  const [index, setIndex] = useState(0); //第1集
  const [list, setList] = useState([]);
  // 清洗Base64字符串，移除非Base64字符及尾部的等号
  let base64Str = window.location.search.slice(1)
  base64Str = base64Str.replace(/[^A-Za-z0-9+/=]/g, '').replace(/=+$/, '');
  const query = atob(base64Str);
  const params = new URLSearchParams(query);
  const flag = params.get("flag");
  const id = params.get("id");
  const asyncFetch = () => {
    fetch(`https://vip.huidge.asia/api.php?flag=${flag}&id=${id}`)
      .then((response) => response.json())
      .then((result) => {
        const { url, title, info } = result;
        setTitle(title);
        setUrl(url);
        if (!url) {
          Toast.show("播放失败，请稍后重试");
        }
        const array = [];
        info?.[0]?.video?.forEach((item) => {
          const result = item.split("$");
          array.push({
            name: result[0],
            url: result[1],
          });
        });
        setList(array);
      })
      .catch((err) => {
        console.log(err);
        Toast.show("播放失败，请稍后重试");
      });
  };

  useEffect(() => {
    (id) && asyncFetch();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (title) {
      document.title = "正在播放：" + title + ` 第${index + 1}集`;
    }
  }, [title, index]);

  return (
    <div style={{ height: "40vh" }}>
      {(id) ? (
        <>
          {/* <NoticeBar closeable content={'不要相信视频中的任何广告和网站'} color='error' /> */}
          <NoticeBar closeable content={'免责声明：本站搜索结果数据均来源于网络外部引用，本站不存储不制作任何视频！如有侵权，请联系作者删除，谢谢合作！不要相信视频中的任何广告和网站'} color='alert' />
          <Video url={url} />
          <Grid columns={4} gap={2} style={{ padding: 10 }}>
        {list?.map((item, index) => (
          <Grid.Item
            onClick={() => {
              setUrl(item.url);
              setIndex(index);
            }}
            key={item.url}
          >
            <Button
              color="primary"
              fill={item.url === url ? "solid" : "outline"}
            >
              {item.url === url ? "播放中" : item.name}
            </Button>
          </Grid.Item>
        ))}
          </Grid>
          {/* <a href={'http://101.43.23.203:81/index.php/%e7%be%8e%e9%a3%9f%e5%9b%a2%e8%b4%ad%e7%a6%8f%e5%88%a9/'} target="_blank" style={{color:'red',fontSize:24}}>美食福利</a> */}
          
        </>
      ) : (
        <Feedback />
      )}
    </div>
  );
}

export default App;
