import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Detail from "./Detail";
import DetailCopy from "./Detail_copy";
import Play from "./Play";
import Feedback from "./Feedback";
import Gallery from "./Gallery";
import Recommend from "./Recommend";
// import Index from "./Index/index";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Index />}></Route> */}
        <Route path="/" element={<DetailCopy />}></Route>
        <Route path="/detail" element={<Detail />}></Route>
        <Route path="/play" element={<Play />}></Route>
        <Route path="/recommend" element={<Recommend />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
        <Route path="/feedback" element={<Feedback />}></Route>
        <Route path="/home" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
