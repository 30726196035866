import React, { useState, useEffect } from "react";
import { NavBar, TabBar, Card, Space, Badge } from "antd-mobile";
import {
  SearchOutline,
  MoreOutline,
  AppOutline,
  MessageOutline,
  MessageFill,
  UnorderedListOutline,
  UserOutline,
} from "antd-mobile-icons";
import styles from "./index.module.less";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [title, setTitle] = useState([]);
  const [list, setList] = useState([]);
  const tabs = [
    {
      key: "home",
      title: "首页",
      icon: <AppOutline />,
      badge: Badge.dot,
    },
    {
      key: "todo",
      title: "待办",
      icon: <UnorderedListOutline />,
      badge: "5",
    },
    {
      key: "message",
      title: "消息",
      icon: (active: boolean) =>
        active ? <MessageFill /> : <MessageOutline />,
      badge: "99+",
    },
    {
      key: "personalCenter",
      title: "我的",
      icon: <UserOutline />,
    },
  ];
  const asyncFetch = () => {
    const search = window.location.search;
    fetch(`/wx/api.php${search}`)
      .then((response) => response.json())
      .then((result) => {
        const { title, info } = result;
        setTitle(title);
        setList(info);
      });
  };

  useEffect(() => {
    asyncFetch();
  }, []);

  return (
    <div className={styles.app}>
      {/* <SearchBar placeholder="请输入内容" showCancelButton /> */}
      <div className={styles.top}>
        <NavBar
          onBack={() => {}}
          right={
            <div style={{ fontSize: 24 }}>
              <Space style={{ "--gap": "16px" }}>
                <SearchOutline />
                <MoreOutline />
              </Space>
            </div>
          }
        >
          {title}
        </NavBar>
      </div>
      <div className={styles.body}>
        {list?.map((item) => {
          const { type, title, flag, id } = item;
          return (
            <Card
              title={type}
              onClick={() => {
                navigate(`/detail?flag=${flag}&id=${id}`);
              }}
              style={{ margin: 10 }}
            >
              {title}
            </Card>
          );
        })}
      </div>

      <div className={styles.bottom}>
        <TabBar safeArea>
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  );
}

export default App;
