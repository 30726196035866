import React from "react";
import { Grid, Popup, Button } from "antd-mobile";
const App = (props) => {
  const { visible, setVisible, list, setIndex, url, setUrl } = props;
  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
      onClose={() => {
        setVisible(false);
      }}
      showCloseButton
      bodyStyle={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        minHeight: "50vh",
        maxHeight: "80vh",
        overflow: "scroll",
      }}
    >
      <h2 style={{ textAlign: "center", color: "#000" }}>选集</h2>
      <Grid columns={4} gap={2} style={{ padding: 10 }}>
        {list?.map((item, index) => (
          <Grid.Item
            onClick={() => {
              setUrl(item.url);
              setIndex(index);
            }}
            key={item.url}
          >
            <Button
              color="primary"
              fill={item.url === url ? "solid" : "outline"}
            >
              {item.url === url ? "播放中" : item.name}
            </Button>
          </Grid.Item>
        ))}
      </Grid>
    </Popup>
  );
};

export default App;
